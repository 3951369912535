<template>
  <div class="form">
    <a-spin :spinning="loading">
      <div class="info" v-if="orderDetail && !showCombo">
        <div class="table-title">
          {{ orderDetail.tableName }}
        </div>
        <CInfo
          title="姓名"
          :labelCol="7"
          :valueCol="12"
          :value="
            orderDetail.customerName +
            '(' +
            ['未知', '先生', '女士'][orderDetail.customerSex] +
            ')'
          "
        />
        <CInfo
          v-if="orderDetail.customerMobile"
          title="手机号码"
          :labelCol="7"
          :valueCol="12"
          :value="orderDetail.customerMobile"
        />
        <CInfo
          title="就餐时间"
          :labelCol="7"
          :valueCol="12"
          :value="$filters.formatDate(orderDetail.bookTime, 'MM-DD HH:mm')"
        />
        <CInfo
          title="就餐人数"
          :labelCol="7"
          :valueCol="12"
          :value="orderDetail.peopleCount"
        />
        <CInfo
          v-if="!editingRemark"
          title="就餐备注"
          :labelCol="7"
          :valueCol="12"
          :value="orderDetail.customerRemark"
        >
          <template #suffix>
            <div>
              <a-tooltip>
                <template #title>修改备注</template>
                <a @click.stop="onEditRemark(orderDetail.customerRemark)">
                  <EditOutlined />
                </a>
              </a-tooltip>
            </div>
          </template>
        </CInfo>

        <CTextarea
          v-if="editingRemark"
          title="就餐备注"
          :labelCol="7"
          :valueCol="12"
          v-model:value="customerRemark"
        >
          <template #suffix>
            <div class="time-btns">
              <a-tag color="#f50" style="display: block; height: 24px">
                <a
                  @click="onEditRemarkSave"
                  style="color: #fff; font-weight: 400"
                >
                  保存
                </a>
              </a-tag>
              <a-tag color="#2db7f5" style="display: block; height: 24px">
                <a
                  @click="onEditRemark(orderDetail.customerRemark)"
                  style="color: #fff; font-weight: 400"
                >
                  取消
                </a>
              </a-tag>
            </div>
          </template>
        </CTextarea>

        <CInfo
          title="订单金额"
          v-if="orderDetail.payAmount"
          :labelCol="7"
          :valueCol="12"
          :value="orderDetail.payAmount"
        />

        <CInfo
          title="菜品金额"
          v-if="orderDetail.caijinAmount"
          :labelCol="7"
          :valueCol="12"
          :value="orderDetail.caijinAmount"
        />

        <CSelect
          title="服务员"
          :labelCol="7"
          :valueCol="14"
          v-model:value="orderDetail.waiterId"
          :options="waiters"
          @ok="onSetWaiter"
        />
      </div>

      <div class="items" v-if="orderDetail">
        <div :class="{ 'item-title': true, on: isEditCombo }" v-if="!showCombo">
          <div class="top">
            <div class="item-title-box">
              <div>
                {{ orderDetail.comboName }}
                <span class="canbiao">¥{{ orderDetail.comboPrice }}元/人</span>
              </div>
            </div>
            <div class="item-btns">
              <a-tag
                color="#108ee9"
                v-if="!isEditCombo"
              >
                <a @click="editCombo" style="color: #fff; font-weight: 400">
                  修改套餐
                </a>
              </a-tag>
              <a-tag
                color="#f50"
                v-if="isEditCombo"
                style="display: block; height: 24px"
              >
                <a @click="onEditCombo" style="color: #fff; font-weight: 400">
                  保存
                </a>
              </a-tag>
              <a-tag
                color="#2db7f5"
                v-if="isEditCombo"
                style="display: block; height: 24px"
              >
                <a @click="editCombo" style="color: #fff; font-weight: 400">
                  取消
                </a>
              </a-tag>
            </div>
          </div>

          <div class="edit-combo">
            <CSelect
              title="修改餐标"
              :labelCol="7"
              :valueCol="14"
              v-model:value="changeComboData.comboPrice"
              :options="comboUnit"
              @ok="onChangeComboUnit"
            />
            <CSelect
              title="修改人数"
              :labelCol="7"
              :valueCol="14"
              v-model:value="changeComboData.peopleCount"
              :options="peoples"
              @ok="onChangePeoples"
            />
            <CSelect
              title="选择套餐"
              :labelCol="7"
              :valueCol="14"
              v-model:value="changeComboData.comboId"
              :options="combos"
              @ok="onChangeCombo"
            />
          </div>
        </div>

        <div class="item" v-for="(v, k) in orderItems2" :key="k">
          <span>
            <a-tag color="green" v-if="v.state === 0">加菜</a-tag>
            <a-tag color="orange" v-if="v.state === 1">赠菜</a-tag>
            <a-tag color="red" v-if="v.timing === 1">等叫</a-tag>
            {{ v.dish.name }}
          </span>
          <div class="item-right">
            <div>
              <a-tooltip v-if="v.state != 1">
                <template #title>减少</template>
                <a v-if="v.quantity > 0" @click.stop="onMinusQuantity(v)">
                  <MinusOutlined />
                </a>
                <span v-else style="color: #ccc">
                  <MinusOutlined />
                </span>
              </a-tooltip>
              <a-divider  v-if="v.state != 1" type="vertical" />
              <span>
                <CloseOutlined style="font-size: 10px" />{{ v.quantity }}
              </span>
              <a-divider  v-if="v.state != 1" type="vertical" />
              <a-tooltip  v-if="v.state != 1">
                <template #title>增加</template>
                <a @click.stop="onPlusQuantity(v)">
                  <PlusOutlined />
                </a>
              </a-tooltip>
            </div>

            <div class="item-action">
              <a-divider type="vertical" />
              <a-tooltip>
                <template #title>删除</template>
                <a @click.stop="delDishFun(v)">
                  <DeleteOutlined />
                </a>
              </a-tooltip>
              <a-divider type="vertical"  v-if="v.state != 1" />
              <a-tooltip  v-if="v.state != 1">
                <template #title>转台</template>
                <a @click.stop="$refs.moveDish.open(v)">
                  <icon-font type="icon-arrow-" />
                </a>
              </a-tooltip>
            </div>
          </div>
        </div>

        <div class="item" v-for="(v, k) in orderItems3" :key="k">
          <span>
            <a-tag color="blue">酒水</a-tag>
            {{ v.dish.name }}
          </span>
          <div class="item-right">
            <div>
              <a-tooltip>
                <template #title>减少</template>
                <a v-if="v.quantity > 0" @click.stop="onMinusQuantity(v)">
                  <MinusOutlined />
                </a>
                <span v-else style="color: #ccc">
                  <MinusOutlined />
                </span>
              </a-tooltip>
              <a-divider type="vertical" />
              <span>
                <CloseOutlined style="font-size: 10px" />{{ v.quantity }}
              </span>
              <a-divider type="vertical" />
              <a-tooltip>
                <template #title>增加</template>
                <a @click.stop="onPlusQuantity(v)">
                  <PlusOutlined />
                </a>
              </a-tooltip>
            </div>

            <div class="item-action">
              <a-divider type="vertical" />
              <a-tooltip>
                <template #title>删除</template>
                <a @click.stop="delDishFun(v)">
                  <DeleteOutlined />
                </a>
              </a-tooltip>
            </div>
          </div>
        </div>

        <div class="item" v-for="(v, k) in orderItems4" :key="k">
          <span>
            <a-tag color="red">损耗</a-tag>
            {{ v.dish.name }}
          </span>
          <div class="item-right">
            <div>
              <a-tooltip>
                <template #title>减少</template>
                <a v-if="v.quantity > 0" @click.stop="onMinusQuantity(v)">
                  <MinusOutlined />
                </a>
                <span v-else style="color: #ccc">
                  <MinusOutlined />
                </span>
              </a-tooltip>
              <a-divider type="vertical" />
              <span>
                <CloseOutlined style="font-size: 10px" />{{ v.quantity }}
              </span>
              <a-divider type="vertical" />
              <a-tooltip>
                <template #title>增加</template>
                <a @click.stop="onPlusQuantity(v)">
                  <PlusOutlined />
                </a>
              </a-tooltip>
            </div>

            <div class="item-action">
              <a-divider type="vertical" />
              <a-tooltip>
                <template #title>删除</template>
                <a @click.stop="delDishFun(v)">
                  <DeleteOutlined />
                </a>
              </a-tooltip>
            </div>
          </div>
        </div>

        <div class="item" v-for="(v, k) in orderItems5" :key="k">
          <span>
            <a-tag color="red" v-if="v.targetOrderId === orderId">{{v.fromTableName}}</a-tag>
            <a-tag color="red" v-else>{{v.toTableName}}</a-tag>
            {{ v.dish.name }}
          </span>
          <div class="item-right">
            <div>
              <a-tooltip v-if="v.targetOrderId !== orderId">
                <template #title>减少</template>
                <a v-if="v.quantity > 0" @click.stop="onMinusQuantity(v)">
                  <MinusOutlined />
                </a>
                <span v-else style="color: #ccc">
                  <MinusOutlined />
                </span>
              </a-tooltip>
              <a-divider type="vertical" v-if="v.targetOrderId !== orderId" />
              <span>
                <CloseOutlined style="font-size: 10px" />{{ v.quantity }}
              </span>
              <a-divider type="vertical" v-if="v.targetOrderId !== orderId" />
              <a-tooltip v-if="v.targetOrderId !== orderId">
                <template #title>增加</template>
                <a @click.stop="onPlusQuantity(v)">
                  <PlusOutlined />
                </a>
              </a-tooltip>
            </div>

            <div class="item-action" v-if="v.targetOrderId !== orderId">
              <a-divider type="vertical" />
              <a-tooltip>
                <template #title>删除</template>
                <a @click.stop="delDishFun(v)">
                  <DeleteOutlined />
                </a>
              </a-tooltip>
            </div>
          </div>
        </div>

        <div class="item" v-for="(v, k) in orderItems" :key="k">
          <span>{{ v.dish.name }}</span>
          <div class="item-right">
            <div class="item-action">
              <a-tooltip>
                <template #title>减少</template>
                <a v-if="v.quantity > 0" @click.stop="onMinusQuantity(v)">
                  <MinusOutlined />
                </a>
                <span v-else style="color: #ccc">
                  <MinusOutlined />
                </span>
              </a-tooltip>
              <a-divider type="vertical" />
              <span>{{ v.quantity }}</span>
              <a-divider type="vertical" />
              <a-tooltip>
                <template #title>增加</template>
                <a @click.stop="onPlusQuantity(v)">
                  <PlusOutlined />
                </a>
              </a-tooltip>
            </div>
          </div>
        </div>
      </div>
    </a-spin>

    <EChangeQuantity ref="eChangeQuantity" @ok="onChangeDishCount" />
    <MoveDish ref="moveDish" @ok="onMoveDish" />
  </div>
</template>

<script>
import { defineComponent, ref, toRaw, watch, reactive, createVNode } from 'vue'
import CInfo from '@/components/counter/form/Info'
import { OrderClass, OrderItemClass } from '@/apis/order'
import { UserClass } from '@/apis/user'
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  MinusOutlined,
  QuestionCircleOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons-vue'
import { Modal, message } from 'ant-design-vue'
import CSelect from '@/components/counter/form/Select'
import EChangeQuantity from '@/components/counter/forms/table/ChangeQuantity'
import MoveDish from '@/components/counter/forms/MoveDish'
import CTextarea from '@/components/counter/form/Textarea'

const order = new OrderClass()
const orderItem = new OrderItemClass()
const user = new UserClass()
export default defineComponent({
  components: {
    CInfo,
    CloseOutlined,
    CSelect,
    DeleteOutlined,
    PlusOutlined,
    MinusOutlined,
    EChangeQuantity,
    CTextarea,
    EditOutlined,
    MoveDish
  },

  props: {
    orderId: {
      type: String,
      default: ''
    },
    showCombo: {
      type: Boolean,
      default: false
    }
  },

  emits: ['change'],
  setup (props, { emit }) {
    const loading = ref(false)
    const orderDetail = ref()
    const orderItems = ref([])
    const orderItems2 = ref([])
    const orderItems3 = ref([])
    const orderItems4 = ref([])
    const orderItems5 = ref([])

    const waiters = ref([])

    const isEditCombo = ref(false)
    const changeComboData = reactive({
      comboPrice: 0,
      peopleCount: 0,
      id: 0,
      comboId: 0
    })

    const allCombos = ref([])
    const combos = ref([])
    const comboUnit = ref([])
    const peoples = ref([])
    const combo = ref('')

    const changeOrderItem = ref()

    const onGetOrder = (id) => {
      isEditCombo.value = false
      loading.value = true
      order.detail(id).then((resp) => {
        orderDetail.value = resp
        // 必点品
        orderItems.value = resp.orderItems.filter((x) => x.dish.isMust)
        // 加菜或赠菜
        orderItems2.value = resp.orderItems.filter((x) => x.type === 4)
        // 酒水
        orderItems3.value = resp.orderItems.filter((x) => x.type === 2)
        // 损耗
        orderItems4.value = resp.orderItems.filter((x) => x.type === 3)
        // 跨台赠菜
        orderItems5.value = resp.orderItems.filter((x) => x.type === 5)
        loading.value = false

        changeComboData.comboId = resp.comboId
        changeComboData.comboPrice = resp.comboPrice
        changeComboData.peopleCount = resp.peopleCount
        changeComboData.id = resp.id
      })
    }

    const onRefresh = () => {
      onGetOrder(props.orderId)
    }

    onGetOrder(props.orderId)

    watch(props, (e) => {
      onGetOrder(e.orderId)
    })

    const editCombo = () => {
      isEditCombo.value = !isEditCombo.value

      if (isEditCombo.value) {
        const combolist = allCombos.value.filter(
          (x) =>
            x.peopleCount === changeComboData.peopleCount &&
            x.unitPrice === changeComboData.comboPrice
        )

        const combosV = []

        combolist.map((e) => {
          combosV.push({
            label: e.name,
            value: e.id
          })
        })

        combos.value = combosV
      }
    }

    const onChangeCombo = (e) => {
      changeComboData.comboId = e
      console.log(changeComboData.comboId)
    }

    const onChangeComboUnit = (e) => {
      const all = allCombos.value.filter((x) => x.unitPrice === e)
      const comboList = allCombos.value.filter(
        (x) =>
          x.unitPrice === e && x.peopleCount === changeComboData.peopleCount
      )

      changeComboData.comboId = ''

      const selectCombos = []
      const selectPeoples = []
      const selectPeoplesV = []

      all.map((e) => {
        if (!selectPeoples.includes(e.peopleCount)) {
          selectPeoples.push(e.peopleCount)
        }
      })

      comboList.map((e) => {
        if (selectCombos.filter((x) => x.label === e.name).length === 0) {
          selectCombos.push({
            label: e.name,
            value: e.id
          })
        }
      })

      selectPeoples.map((e) => {
        selectPeoplesV.push({
          label: e + '人',
          value: e
        })
      })

      peoples.value = selectPeoplesV
      combos.value = selectCombos

      const cur = changeComboData.peopleCount
      const has = selectPeoplesV.filter((x) => x.value === cur)
      if (has.length === 0) {
        changeComboData.peopleCount = selectPeoplesV[0].value
      }
    }

    const onChangePeoples = (e) => {
      const all = allCombos.value.filter((x) => x.peopleCount === e)
      const comboList = allCombos.value.filter(
        (x) => x.unitPrice === changeComboData.comboPrice && x.peopleCount === e
      )

      changeComboData.comboId = ''

      const selectCombos = []
      const selectComboUnits = []
      const selectComboUnitsV = []
      all.map((e) => {
        if (!selectComboUnits.includes(e.unitPrice)) {
          selectComboUnits.push(e.unitPrice)
        }
      })

      comboList.map((e) => {
        if (selectCombos.filter((x) => x.label === e.name).length === 0) {
          selectCombos.push({
            label: e.name,
            value: e.id
          })
        }
      })
      selectComboUnits.map((e) => {
        selectComboUnitsV.push({
          label: e + '元',
          value: e
        })
      })

      comboUnit.value = selectComboUnitsV
      combos.value = selectCombos

      const cur = changeComboData.comboPrice
      const has = selectComboUnitsV.filter((x) => x.value === cur)
      if (has.length === 0) {
        changeComboData.comboPrice = selectComboUnitsV[0].value
      }
    }

    const onEditCombo = () => {
      const data = { ...toRaw(changeComboData) }

      loading.value = true
      order
        .update(data)
        .then(() => {
          isEditCombo.value = false
          emit('change')
          onGetOrder(props.orderId)
        })
        .catch(() => {
          loading.value = false
        })
    }

    const minusDishFun = (e) => {
      Modal.confirm({
        title: '注意！',
        icon: createVNode(QuestionCircleOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:red;'
          },
          '请确认是否减少一件' + e.dish.name
        ),
        okText: '减掉一件',
        onOk () {
          onMath(e.id, 0)
        },

        onCancel () {
          console.log('Cancel')
        }
      })
    }

    const plusDishFun = (e) => {
      Modal.confirm({
        title: '注意！',
        icon: createVNode(QuestionCircleOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:red;'
          },
          '请确认是否添加一件' + e.dish.name
        ),
        okText: '添加一件',
        onOk () {
          onMath(e.id, 1)
        },

        onCancel () {
          console.log('Cancel')
        }
      })
    }

    const delDishFun = (e) => {
      Modal.confirm({
        title: '警告！',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:red;'
          },
          '请确认是否删除菜品：' + e.dish.name
        ),
        okText: '删除',
        onOk () {
          console.log('删除')
          loading.value = true
          orderItem.del(e.id).then(() => {
            message.success('删除菜品成功')
            onGetOrder(props.orderId)
            emit('change')
          })
        },

        onCancel () {
          console.log('Cancel')
        }
      })
    }

    const onMath = (id, type, count = 1) => {
      loading.value = true
      orderItem.math(id, type, count).then(() => {
        message.success((type === 0 ? '减少' : '添加') + '操作成功')
        onGetOrder(props.orderId)
        emit('change')
      })
    }

    const onGetWaiters = () => {
      user.waiters().then((resp) => {
        const list = []
        resp.map((e) => {
          list.push({
            label: e.name + (e.isService ? '(服务中)' : '(空闲中)'),
            value: e.id
          })
        })

        waiters.value = list
      })
    }

    onGetWaiters()

    const onSetWaiter = (e) => {
      const orderId = props.orderId
      const userId = e
      order.setWaiter(orderId, userId).then(() => {
        message.success('修改服务员成功')
        emit('change')
      })
    }

    const editingRemark = ref(false)
    const customerRemark = ref('')
    const onEditRemark = (e) => {
      editingRemark.value = !editingRemark.value
      customerRemark.value = e
    }

    const onEditRemarkSave = () => {
      order
        .editRemark({
          orderId: props.orderId,
          customerRemark: customerRemark.value
        })
        .then(() => {
          editingRemark.value = false
          emit('change')
          onGetOrder(props.orderId)
        })
    }

    return {
      sexes: [
        {
          label: '先生',
          value: 1
        },
        {
          label: '女士',
          value: 2
        }
      ],
      orderDetail,
      orderItems,
      loading,
      isEditCombo,
      changeComboData,
      allCombos,
      combos,
      peoples,
      orderItems2,
      orderItems3,
      orderItems4,
      orderItems5,
      comboUnit,
      combo,
      waiters,
      changeOrderItem,
      editingRemark,
      customerRemark,

      onGetOrder,
      editCombo,
      onChangeCombo,
      onChangeComboUnit,
      onChangePeoples,
      onEditCombo,
      minusDishFun,
      plusDishFun,
      delDishFun,
      onSetWaiter,
      onMath,
      onRefresh,

      onEditRemark,
      onEditRemarkSave
    }
  },

  created () {
    this.onGetAllCombos()
  },

  unmounted () {
    this.isEditCombo = false
  },

  methods: {
    async onGetAllCombos () {
      const list =
        this.$store.state.data.ALL_COMBOS.length > 0
          ? this.$store.state.data.ALL_COMBOS
          : await this.$store.dispatch('GetAllCombos')
      this.allCombos = list

      const peoples = []
      const comboUnit = []
      const len = list.length
      for (let i = 0; i < len; i++) {
        if (!peoples.includes(list[i].peopleCount)) {
          peoples.push(list[i].peopleCount)
        }
      }

      for (let i = 0; i < len; i++) {
        if (!comboUnit.includes(list[i].unitPrice)) {
          comboUnit.push(list[i].unitPrice)
        }
      }

      const selectPeoples = []
      const selectCombos = []

      peoples.map((e) => {
        selectPeoples.push({
          label: e + '人',
          value: e
        })
      })

      comboUnit.map((e) => {
        selectCombos.push({
          label: e + '元',
          value: e
        })
      })

      this.peoples = selectPeoples
      this.comboUnit = selectCombos
    },

    refresh (orderId = null) {
      this.onGetOrder(orderId || this.orderId)
    },

    onPlusQuantity (item) {
      this.$refs.eChangeQuantity.open(item, 1)
    },

    onMinusQuantity (item) {
      this.$refs.eChangeQuantity.open(item, 0)
    },

    onChangeDishCount (e) {
      this.onMath(e.id, e.type, e.count)
      this.$refs.eChangeQuantity.handleClose()
    },

    onMoveDish () {
      this.onGetOrder(this.orderId)
    }
  }
})
</script>

<style lang="less" scoped>
.form {
  .info {
    background: #ffdcdc;
    padding: 0.2rem 0;
    .table-title {
      font-size: 0.18rem;
      padding-left: 0.5rem;
      line-height: 0.5rem;
    }

    .time-btns {
      display: flex;
      padding-top: 5px;
    }
  }

  .items {
    .item-title {
      height: 0.5rem;
      line-height: 0.5rem;
      padding: 0 0.2rem;
      border-bottom: 3px solid #ebebeb;
      font-size: 0.16rem;
      color: #333;
      font-weight: 600;
      position: relative;
      overflow: hidden;
      transition: all 0.2s ease;
      background: #f8f8f8;
      .top{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .item-title-box {
        width: 3rem;
        height: 0.5rem;
        display: flex;
        align-items: center;
        line-height: 0.2rem;
        font-size: 0.14rem;
      }
      .item-btns{
        display: flex;
        align-items: center;
      }
      .canbiao {
        color: #ff4f4f;
      }
      .edit-combo {
        position: absolute;
        bottom: -10rem;
        left: 0;
        width: 100%;
        font-weight: 400;
        transition: all 0.2s ease;
      }
      &.on {
        height: 250px;
        .edit-combo {
          bottom: 0;
        }
      }
    }

    .item {
      padding: 0 0.2rem;
      font-size: 0.14rem;
      line-height: 0.5rem;
      border-bottom: 1px solid #ebebeb;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #666;

      .item-right {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
</style>
